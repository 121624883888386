import React from "react"

import { Section, StyledContainer } from "../../global"

const PogojiDescription = () => (
  <Section>
    <StyledContainer>
      <h4>Splošni pogoji poslovanja</h4>
      <h5>Podatki:</h5>
      <p>Boštjan Hari s.p.</p>
      <p>Češnjice 11</p>
      <p>1251 Moravče</p>
      <p>E-pošta: info@salom.si</p>
      <p>Matična številka: 5026237</p>
      <p>Davčna številka: 40502368</p>
      <p>TRR: SI56 031001003272846</p>
      <p>Boštjan Hari s.p. ni davčni zavezanec</p>
      <h4>Pogoji poslovanja</h4>
      <p>Splošni pogoji poslovanja spletne trgovine www.salom.si so sestavljeni v skladu z Zakonom o varstvu potrošnikov, s priporočili GZS in mednarodnimi kodeksi za e-poslovanje ter določajo:</p>
      <ul>
        <li>Nakupovanje (možnosti in oblike)</li>
        <li>Načini dostave</li>
        <li>Čas dostave</li>
        <li>Plačilne pogoje</li>
        <li>Cene</li>
        <li>Prednaročila</li>
        <li>Vračilo blaga</li>
        <li>Reklamacije</li>
        <li>Varovanje osebnih podatkov</li>
        <li>Druga določila</li>
      </ul>
      <p>Kupec potrjuje, da je seznanjen in se strinja s prodajnimi pogoji. Naročilo kupca ima značaj pogodbe in zavezuje prodajalca in kupca.</p>
      <h4>Nakupovanje</h4>
      <p>
        Spletna stran www.salom.si obiskovalcem predstavlja prodajni program Boštjan Hari s.p. in njegovih partnerjev ter omogoča nakupovanje izdanih knjig v fizični ali elektronski obliki (e-knjiga).
        Naročilo zavezuje tako Boštjana Harija s.p. kot prodajalca in kupca, ki želi opraviti spletni nakup preko strani www.salom.si ter ima značaj pogodbe. Naročilo skupaj z računom se hrani na sedežu Boštjana Harija s.p. in bo dostopno potrošniku.
      </p>
      <h4>Načini dostave</h4>
      <p>Za dostavo naročil uporabljamo storitve Pošte Slovenije. Naročene izdelke dostavimo na naslov, ki ga kupec navede kot naslov za dostavo. Dostave potekajo večinoma v dopoldanskem času. V primeru, da v času dostave pošiljke ne morete prevzeti, jo lahko kasneje, v roku 15 dni, prevzamete pri najbližji enoti Pošte Slovenije v skladu s Splošnimi pogoji in določili Pošte Slovenije. Strošek poštnine znaša 2,7€ (ddv vključen) in ni vštet v ceno artikla, kupljenega preko spletne strani www.salom.si</p>
      <h4>Naslovi za dostavo</h4>
      <p>
        Izdelek lahko kupcu dostavimo na vsak naslov v Sloveniji ali tujini. Naročila pravnih oseb lahko dostavimo le na naslov uradnega sedeža podjetja ali naslove uradnih poslovnih enot. V primeru dostave v tujino, so stroški dostave zvišani glede na uradni cenik pošiljanja v tujino Pošte Slovenije. O stroških dostave v tujino bo Boštjan Hari s.p. kupca obvestil pred pošiljanjem naročenega izdelka.
        Spletnih naročil na sedežu Boštjan Hari s.p. ni možno prevzemati.
        Vsa naročila, ki so bila preko spletne strani www.salom.si sprejeta med delavniki do 13:00 ure, bodo odposlana še isti dan (za delovni dan v Republiki Sloveniji upoštevamo vsak dan med ponedeljkom in petkom, sobot, nedelj in praznikov ne štejemo kot delovne dneve). Glede na to, da imamo artikle na zalogi, bodo ti do vas dostavljeni znotraj treh delovnih dni (običajno že naslednji delovni dan), v skladu s splošnimi pogoji dostave Pošte Slovenije.
      </p>
      <h4>Strošek za dostavo</h4>
      <p>
        Če ob izdelku eksplicitno ni navedeno opozorilo o brezplačni dostavi ob nakupu, strošek dostave naročenih izdelkov plača kupec. V primeru nakupa večjega števila enakih izdelkov (isti izdelek v večih izvodih se šteje kot najmanj dva enaka izdelka), na to posebej opozorite ob naročilu izdelka. Vsak izdelek je namreč odpremljen v ločeni embalaži, ob nakupu večjega števila enakih izvodov (najmanj dva) bomo embalažo ustrezno prilagodili in s tem ustrezno znižali strošek pošiljanja in dostave, vendar ta ne bo nižji od osnove, ki znaša 2,7€ (ddv vključen). V tem znesku so zajeti stroški manipulacije in pakiranja izdelka ter stroški poštnine.
        V primeru dostave v tujino, so stroški dostave zvišani glede na uradni cenik pošiljanja v tujino Pošte Slovenije. O stroških dostave v tujino bo Zavod Kerigma kupca obvestil pred pošiljanjem naročenega izdelka.
      </p>
      <h4>Plačilni pogoji</h4>
      <p>
        Boštjan Hari s.p. omogoča nakup blaga s plačilom po povzetju ter s plačilom po predračunu.
        Če izberete plačilo po predračunu, boste na vaš e-mail prejeli predračun in ko bo le-ta plačan, bo naročeni izdelek/i tudi odposlan. V primeru, da e-pošte ne uporabljate, vam bomo predračun poslali po navadni pošti, ta oblika dostave predračuna pa potem zamakne rok dostave za čas, ki ga v svojem poslovanju določajo splošni pogoji pisemskih pošiljk v notranjem prometu Pošte Slovenije.
        Dokler kupnina ni v celoti poravnana, in njeno nakazilo razvidno v bančnemu sistemu Boštjan Hari s.p., je izdelek last Boštjana Harija s.p.
        V primeru plačila po povzetju boste naročilo plačali dostavljavcu Pošte Slovenije ob prevzemu.
        Pri plačilu predračuna vam vaša banka preko katere poslujete lahko zaračuna dodaten strošek vezan na transakcijo v skladu s Splošnimi pogoji banke. Ti stroški niso zajeti v ceno izdelka ali poštnine.
      </p>
      <h4>Cene</h4>
      <p>
        Cene izdelkov in poštnine so izražene v evrih (EUR) in že vključujejo 9,5 % oz. 22 % davek na dodano vrednost.
        Redna cena je priporočena maloprodajna cena artikla. Pridržujemo si pravico do sprememb cen ter pravico do napake pri objavi cene.
      </p>
      <h4>Vračilo blaga</h4>
      <p>
        Kupec ima pravico odstopiti od pogodbe o nakupu v 15 dneh od prejema izdelkov, in sicer na enega od dveh načinov:
      </p>
      <ul>
        <li>da vrne knjigo na Boštjan Hari s.p. v roku 15-ih dni od datuma prejema izdelka</li>
        <li>ali, da o odstopu v roku 15-ih dni pisno obvesti Boštjana Harija s.p. preko e-pošte oz. navadne pošte, ter v 15-ih dneh po oddaji tega obvestila na Boštjan Hari s.p. vrne naročeni izdelek oz. izdelke. Stroški dostave vrnjenega blaga bremenijo pošiljatelja.</li>
      </ul>
      <p>
        Pogoj v obeh primerih je, da kupec, ki želi odstopiti od pogodbe, vrne izdelek na naslov Boštjan Hari s.p., nepoškodovan in v nespremenjeni količini ter v obliki, kot ga je prejel. V kolikor so pogoji izpolnjeni, Boštjan Hari s.p. kupcu povrne celotno kupnino, vključno s stroški poštnine.
        43.d člen ZVPot (Uradni list 38/2014)
        Z uveljavitvijo pravice do odstopa od pogodbe iz 43.č člena tega zakona prenehajo obveznosti strank glede:
      </p>
      <ul>
        <li>izpolnjevanja pogodbe ali</li>
        <li>sklenitve pogodbe, kadar je dal ponudbo za sklenitev pogodbe potrošnik.</li>
      </ul>
      <p>V primeru odstopa od pogodbe podjetje nemudoma oziroma najpozneje v 14 dneh po prejemu obvestila o odstopu od pogodbe vrne vsa prejeta plačila.</p>
      <h5>Obrazec za odstop od pogodbe/vračilo blaga</h5>
      <p>Boštjan Hari s.p. vrne prejeta plačila kupcu z enakim plačilnim sredstvom kot ga je uporabil kupec, razen če je kupec izrecno zahteval uporabo drugega plačilnega sredstva in če kupec zaradi tega ne nosi nobenih stroškov.
        Kupec ne more zahtevati povračila dodatnih stroškov, ki so nastali, če se je izrecno odločil za drugo vrsto pošiljke, kakor je cenovno najugodnejša standardna pošiljka, ki jo ponuja Boštjan Hari s.p. oz. podjetje, ki za Boštjan Hari s.p. opravlja promet s poštnimi pošiljkami, torej Pošta Slovenije.
        Pri prodajnih pogodbah lahko Boštjan Hari s.p. zadrži vračilo prejetih plačil do prevzema vrnjenega izdelka ali dokler kupec ne predloži dokazila, da je izdelek poslal nazaj.
        Če je kupec izdelek že prejel in odstopi od pogodbe, ga vrne ali izroči Boštjanu Hariju s.p., nemudoma oziroma najpozneje v 14 dneh po obvestilu iz prvega odstavka 43.č člena tega zakona. Šteje se, da kupec pravočasno vrne izdelek, če ga pošlje pred iztekom 14 dnevnega roka za vračilo.
        Kupec v zvezi z odstopom od pogodbe krije samo stroške vračila izdelka.
        Kupec odgovarja za zmanjšanje vrednosti izdelka, če je zmanjšanje vrednosti posledica ravnanja, ki ni nujno potrebno za ugotovitev narave, lastnosti in delovanja izdelka. Kupec ne odgovarja za zmanjšanje vrednosti izdelka, če ga Boštjan Hari s.p. ne seznani s pravico do odstopa od pogodbe skladno s 4. točko prvega odstavka 43.b člena.
        Kupec z uveljavljanjem pravice do odstopa od pogodbe ne prevzema nobenih obveznosti razen obveznosti iz tega člena ter četrtega odstavka 43.d člena in 43.e člena zakona, torej ZVPpot (Uradni list 38/2014)«.
      </p>
      <h4>Reklamacije</h4>
      <p>
        Izjavljamo in se obenem zavezujemo, da bomo upoštevali vse reklamacije, ki jih bomo prejeli pisno ali po elektronski pošti (info@salom.si) v 15-ih dneh po prejemu naročenih izdelkov.
        Za skrite oziroma stvarne napake je v veljavi reklamacijski rok, ki ga predpisujejo veljavni pravilniki v Republiki Sloveniji. Gre za napake, pri katerih blago nima lastnosti, ki so potrebne za njegovo normalno rabo (npr. zamešane strani v knjigi, prazne strani ipd, poškodovane strani, kot je neberljiv tisk, ipd). Pri takšni napaki lahko kupec uveljavlja svoje pravice, če obvesti Boštjan Hari s.p. v roku dveh mesecev od dneva, ko je odkril napako. Napako mora natančno opisati in Boštjanu Hariju s.p. omogočiti, da stvar pregleda. Ne odgovarjamo za stvarne napake na blagu, ki se pokažejo potem ko mineta dve leti, odkar je bila stvar izročena.
        Šteje se, da je napaka na stvari obstajala že v času izročitve, če se pojavi v roku šestih mesecev od izročitve. V primeru pravilnega obvestila Boštjana Harija s.p., ima kupec pravico zahtevati, da se odpravi napaka na blagu ali da se vrne del plačanega zneska v sorazmerju z napako ali da se vrne plačani znesek. Ta pravica ugasne s potekom dveh let od dneva, ko je kupec o stvarni napaki obvestil Boštjan Hari s.p. Ta določila so v skladu z ZVPpot.
        Po 37.c členu ZVPot ima potrošnik, ki je pravilno obvestil prodajalca o napaki, pravico od prodajalca zahtevati, da:
      </p>
      <ul>
        <li>odpravi napako na blagu ali</li>
        <li>vrne del plačanega zneska v sorazmerju z napako ali</li>
        <li>blago z napako zamenja z novim, brezhibnim blagom ali</li>
        <li>vrne plačani znesek.</li>
      </ul>
      <p>Pridržujemo si pravico do presojanja upravičenosti reklamacije.
        Vse spore bosta kupec in prodajalec reševala sporazumno. Če to ne bo mogoče, bo spor reševalo pristojno sodišče v Ljubljani.</p>
      <h4>Varovanje osebnih podatkov</h4>
      <p>
        Kupec dovoljuje, da Boštjan Hari s.p. vzpostavi, vodi, vzdržuje in upravlja s pridobljenimi osebnimi podatki. Podatke, ki jih posredujete na spletno stran www.salom.si, zbiramo izključno za interne potrebe oz. za izvedbo naročila. Zavezujemo se, da bomo s podatki ravnali v skladu z določili Zakona o varstvu osebnih podatkov (Ur. l. RS št. 86/2004, 94/2007), ter da bomo vse podatke uporabljali izključno za lastno evidenco in jih v nobenem primeru in pogoji ne bomo posredovali tretji osebi.
        Boštjan Hari s.p. lahko uporablja osebne podatke posameznikov, ki jih je zbral iz javno dostopnih virov ali v okviru zakonitega opravljanja dejavnosti, tudi za namene ponujanja blaga, storitev, zaposlitev ali začasnega opravljanja del z uporabo poštnih storitev, telefonskih klicev, elektronske pošte ali drugih telekomunikacijskih sredstev (v nadaljnjem besedilu: neposredno trženje z namenom izboljšanja uporabniške izkušnje) v skladu z določbami tega poglavja, če drug zakon ne določa drugače.
        Za namene neposrednega trženja lahko Boštjan Hari s.p. uporablja le osebne podatke, ki jih je zbral v skladu s prejšnjim odstavkom: osebno ime, naslov stalnega ali začasnega prebivališča, telefonsko številko, če je to kupec podal, ter naslov elektronske pošte. Na podlagi osebne privolitve posameznika lahko Boštjan Hari s.p. obdeluje tudi druge osebne podatke, občutljive osebne podatke pa le, če ima za to osebno privolitev posameznika, ki je izrecna in praviloma pisna ter dostopna na spletni strani www.salom.si.
      </p>
      <h4>Ostala določila</h4>
      <p>
        Kupcu pripadajo vse pravice, ki jih odreja Zakon o varstvu potrošnikov.
        Vsi zgoraj navedeni pogoji in pravila so zavezujoči tako za kupca kot tudi za Boštjana Harija s.p.
        V primeru, da se pred ali med nakupom pojavijo težave, nas o tem prosimo nemudoma obvestite na elektronski naslov info@salom.si
      </p>
    </StyledContainer>
  </Section>
)

export default PogojiDescription
