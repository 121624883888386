import React from "react"
import styled from "styled-components"
import { HeaderWrapper } from "../../global"

const PogojiHeader = () => {

  return (
    <BookCoverWrapper>
      <h1>
        Splošni pogoji poslovanja
      </h1>
    </BookCoverWrapper>
  )
}

export default PogojiHeader

const BookCoverWrapper = styled(HeaderWrapper)`
  text-align: center;
`