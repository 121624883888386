import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Footer from "../components/common/footer"
import PogojiHeader from "../components/sections/pogoji-uporabe/pogojiheader"
import PogojiDescription from "../components/sections/pogoji-uporabe/pogojidescription"


const SplosniPogojiPoslovanja = () => (
  <Layout>
    <SEO title="Ponudba predavanj" />
    <PogojiHeader />
    <Navigation />
    <PogojiDescription />
    <Footer />
  </Layout>
)

export default SplosniPogojiPoslovanja